<script setup lang="ts">
import type { HTMLAttributes } from "vue";
import { cn } from "@/utils/shadcn";

const props = defineProps<{
  theme?: "default" | "minimal";
  class?: HTMLAttributes["class"];
}>();

const themeClasses = computed(() => {
  if (props.theme === "minimal") {
    return "pb-2 mb-2 border-b border-slate-300";
  }
  return "rounded-lg border border-slate-300 bg-card text-card-foreground shadow-sm";
});
</script>

<template>
  <div :class="cn(themeClasses, props.class)">
    <slot />
  </div>
</template>
